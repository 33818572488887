"use client";

import { TextResources } from "@portal/assets/text/TextResources";
import { ButtonComponent } from "@portal/components/atoms/buttons/main/ButtonComponent";
import Icon from "@portal/components/atoms/icons";
import PortalPageBox from "@portal/components/atoms/portalPageBox";
import { TextLine } from "@portal/components/atoms/v2/textLine";
import Typography from "@portal/components/atoms/v2/typography";
import LayoutNotAuthorized from "@portal/components/organisms/layouts/Layout-NotAuthorized";

const GeneralErrorPage = () => {
  return (
    <LayoutNotAuthorized>
      <PortalPageBox>
        <div className="flex flex-col justify-center items-center space-y-3">
          <Icon name="questionMarkCircleIcon" size="xxxlarge" />
          <div>
            <Typography variant="H6">{TextResources.ERROR_PAGE_TITLE}</Typography>
            <TextLine />
          </div>
          <div className="mt-5">
            <ButtonComponent
              text={TextResources.ERROR_PAGE_GO_HOME}
              wide
              onClick={async () => {
                //For some reason the router.push("/") does not work here at error page
                window.location.href = "/";
              }}
            />
          </div>
        </div>
      </PortalPageBox>
    </LayoutNotAuthorized>
  );
};

export default GeneralErrorPage;
